<template>
  <div class="project-sales-overview">
    <div class="container fluid">
      <overview-table
        :getProjectSalesAPI="saleAPI.getProjectSales"
        detailRouteName="ProjectSalesDetail"
      ></overview-table>
    </div>
  </div>
</template>

<script>
import { projectSales as saleAPI } from "@/api";
import OverviewTable from "@/components/GlobalComponents/Shared/ProjectSales/General/OverviewTable";

export default {
  components: {
    OverviewTable
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      saleAPI
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
